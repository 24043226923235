.event-items {
  
}

.event-item {
  background-color: $event-item_bgcolor;
  border-radius: $border-radius-base;
  display: block;
  @include rem(margin-bottom, 10px);
  // - because event item is link (a html element)
  text-decoration: none !important;
  @include hover-focus-active {
    .event-item__date {
      background-color: $event-item-date-hover_bgcolor;
      transition: $trans-speed-base background-color;
    }
  }
  @include media-breakpoint-up(sm) {
    display: flex;
  }
}

.event-item__date {
  background-color: $event-item-date_bgcolor;
  border: 1px solid $event-item-date_border-color;
  @include bg3dbottom-maker($event-item-date_bgcolor);
  border-radius: $border-radius-base;
  color: $event-item-date_color;
  font-weight: $event-item-date_font-weight;
  @include rem(padding, $event-item_padding-base);
  transition: $trans-speed-base background-color;
  @include media-breakpoint-up(sm) {
    width: $event-item-date_width-sm;
  }
}

.event-item__day {
  display: block;
  @include rem(font-size, $event-item-date-day_font-size);
  line-height: 1;
}

.event-item__month {
  display: block;
  @include rem(font-size, $event-item-date-month_font-size);
  line-height: 1;
  @include rem(margin-top, 1px);
}

.event-item__name {
  color: $event-item-name_color;
  @include rem(font-size, $event-item-name_font-size);
  font-weight: $event-item-name_font-weight;
  @include rem(padding, $event-item_padding-base);
  @include media-breakpoint-up(sm) {
    width: calc(100% - $event-item-date_width-sm);
  }
}