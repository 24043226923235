/**
 * Lightbox2
 */
@import "../../../../node_modules/lightbox2/src/css/lightbox";

$lightboxPath: "#{$image_path}/lightbox";

.lb-cancel {
  background-image: url(#{$lightboxPath}/loading.gif);
}

.lb-nav a.lb-prev {
  background-image: url(#{$lightboxPath}/prev.png);
}

.lb-nav a.lb-next {
  background-image: url(#{$lightboxPath}/next.png);
}

.lb-data .lb-close {
  background-image: url(#{$lightboxPath}/close.png);
}