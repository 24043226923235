.pagination {
  @include rem(margin-top, 40px);
}

.pagination-link {
  @extend .btn;
  @extend .btn--type-1;
}

.pagination-item--disabled {
  .pagination-link {
    cursor: not-allowed;
    opacity: .5;
  }
}

.pagination-item--active {
  .pagination-link {
    @extend .btn--type-3;
  }
}