.navbar {
  background-color: $navbar_bgcolor;
  font-family: $font-family-secondary;
  @include rem(font-size, $navbar_font-size);
  font-weight: $navbar_font-weight;
  line-height: $navbar_line-height / $navbar_font-size;
  margin: 0;
  @include rem(padding-left, $navbar_padding-y);
  @include rem(padding-right, $navbar_padding-y);
  @include media-breakpoint-up(lg) {
    // - position static is because of logo
    // - logo is position absolute and top 0 for header not navbar
    // - bootstrap navbar has default value position relative
    position: static;
  }
  
  .navbar-toggler {
    @include rem(padding, 15px);
  }
  
  .navbar-collapse {
    @include media-breakpoint-up(lg) {
      @include rem(margin-left, $navbar-collapse_margin-left-lg);
    }
  }
  
  .navbar-nav {
    @include media-breakpoint-down(md) {
      @include rem(margin-top, $navbar-link_padding-lg-y);
    }
    @include media-breakpoint-up(lg) {
      align-items: center;
    }
    
    .nav-link {
      color: $navbar-link_color;
      @include media-breakpoint-up(lg) {
        @include rem(padding, $navbar-link_padding-lg-y $navbar-link_padding-lg-x);
      }
    }
    
    .show > .nav-link, 
    .active > .nav-link, 
    .nav-link.show, 
    .nav-link.active {
      color: $navbar-link_hover-color;
    }
    
    .show > .dropdown-toggle {
      background-color: $navbar-dropdown_bgcolor;
      border-top-left-radius: $border-radius-base;
      border-top-right-radius: $border-radius-base;
      color: $navbar-dropdown_color;
      font-weight: $navbar-dropdown_font-weight;
      @include media-breakpoint-down(md) {
        // - padding left and right is here for medium devices because each nav-link
        //   for medium devices is with padding axis x normally 0 but for show dropdown-toggle
        //   has padding axis x because of background color - better design
        @include rem(padding-left, $navbar-link_padding-lg-x);
        @include rem(padding-right, $navbar-link_padding-lg-x);
      }
    }
    
    .dropdown-menu {
      @include rem(font-size, $navbar_font-size);
      line-height: $navbar_line-height / $navbar_font-size;
      margin: 0;
      // - because for example in Edge browser - space between dropdown menu and
      //   dropdown item
      // - reason why rewrite bootstrap value (100%) on 99%
      top: 99%;
      z-index: 1050;
      @include media-breakpoint-up(lg) {
        width: $navbar-dropdown-menu_width-lg;
      }
      
      &.show {
        background-color: $navbar-dropdown_bgcolor;
        border: 0;
        // - border top left and right radius is 0 because this element is joined with 
        //   dropdown-toggle
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: $border-radius-base;
        border-bottom-right-radius: $border-radius-base;
        @include media-breakpoint-up(lg) {
          // - border top right radius is set on base value because dropdown-menu is
          //   wider than dropdown-toggle and is aligned on the left side
          border-top-right-radius: $border-radius-base;
        }
      }
    }
    
    .dropdown-item {
      color: $navbar-dropdown_color;
      font-weight: $navbar-dropdown_font-weight;
      white-space: normal;
      @include rem(padding-left, $navbar-link_padding-lg-x);
      @include rem(padding-right, $navbar-link_padding-lg-x);
      @include hover-focus-active {
        background: none;
      }
      &.active {
        background: none;
      }
    }  
    
    .dropdown-toggle::after {
      // arrow down for nav-link dropdown on the right side
      border: 0; 
      content: "\f107";
      float: right;
      font-family: $font-family-font-awesome;
      margin-left: 0;
      width: auto;
      @include media-breakpoint-up(lg) {
        // arrow down on the bottom of nav-link
        display: block;
        float: none;
        position: relative;
        text-align: center;
        // closer to bottom side of nav-link
        top: -5px;
      }
    }
  }
}

.navbar__logo {
  background-color: $logo_bgcolor;
  border-radius: $border-radius-base;
  @include media-breakpoint-down(md) {
    max-width: $logo_wh;
  }
  @include media-breakpoint-up(lg) {
    @include rem(padding, $logo_padding-lg);
    width: $logo_wh-lg;
    height: $logo_wh-lg;
    position: absolute;
    top: 0;
    z-index: 1500;
  }
}

.navbar__form-search {
  @include media-breakpoint-up(lg) {
    border-left: 1px solid $navbar-form-search_border-color;
    @include rem(margin-left, $navbar-link_padding-lg-x);
  }
}

.navbar__form-control-search {
  border: none;
  color: $navbar-form-search_color;
  @include rem(font-size, $navbar_font-size);
  line-height: $navbar_line-height / $navbar_font-size;
  margin-right: 0 !important;
  @include rem(padding, $navbar-link_padding-lg-y 0);
  // - form control search and btn search in the same row
  // - !important because of bootstrap more specific selector
  width: calc(100% - #{$navbar-btn-search_width}) !important;
  @include hover-focus-active {
    box-shadow: none;
    color: $navbar-form-search_color;
  }
  @include media-breakpoint-up(lg) {
    @include rem(padding-left, $navbar-link_padding-lg-x);
    @include rem(padding-right, $navbar-link_padding-lg-x);
  }
}

.navbar__btn-search {
  // - !important because for bootstrap btn default has more specific selectors
  //   for hover, focus and active and here I can use only one property of each
  //   (cannot rewrite here and also again in hover, focus and active)
  // - because of this reason, better use !important keyword here
  background: none !important;
  border: 0 !important;
  box-shadow: none !important;
  color: $navbar-form-search_color !important;
  padding-left: 0;
  padding-right: 0;
  // - form control search and btn search in the same row
  width: $navbar-btn-search_width;
  // - button text on the right because of right align with the rest right elements of web
  text-align: right;
}