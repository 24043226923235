.article__title {
  @include rem(margin-bottom, $article-title_margin-bottom);
}

.article__pubdate {
  @include rem(font-size, $article-pubdate_font-size);
  text-align: center;
  @include media-breakpoint-up(md) {
    text-align: left;
  }
}

.article__intro {
  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.article__img {
  @include block-center;
  max-height: 200px;
  @include rem(margin-bottom, 20px);
  @include media-breakpoint-up(md) {
    margin: 0;
  }
}

.article__perex {
  @include media-breakpoint-up(md) {
    @include rem(margin-left, 25px);
  }
}