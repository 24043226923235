.banner {
  background: #000 url(#{$image_assets_path}/banner.png) no-repeat top center;
  background-size: cover;
  color: $banner_color;
  font-family: $font-family-secondary;
  // - because of background cover (::after pseudo-element)
  position: relative;
  z-index: 100;
  @include rem(padding-bottom, $banner_padding-y);
  @include rem(padding-top, $banner_padding-y);
  
  &::after {
    background-color: $banner_bgcolor-cover;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    opacity: .8;
  }
}

.banner__content {
  // - must see clearly and because of this reason must be as first in document 
  //   flow (banner has absolute position bg cover)
  position: relative;
  z-index: 1200;
  
  &.banner__content--page {
    @include media-breakpoint-up(lg) {
      @include rem(margin-left, $banner-content-page_margin-left-md);
    }
  }
}

.banner__home-heading {
  @include rem(padding-bottom, $banner-home-heading_padding-bottom);
  @include rem(padding-top, $banner-home-heading_padding-top);
  @include media-breakpoint-up(lg) {
    @include rem(padding-bottom, $banner-home-heading_padding-bottom-lg);
    @include rem(padding-top, $banner-home-heading_padding-top-lg);
  }
}

.banner__title {
  font-weight: $banner-title_font-weight;
  
  &.banner__title--home {
    @include rem(font-size, $banner-title-home_font-size);
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
      @include rem(font-size, $banner-title-home_font-size-lg);
    }
  }
  
  &.banner__title--page {
    @include rem(font-size, $banner-title-page_font-size);
    @include rem(margin-bottom, $banner-title-page_margin-bottom);
  }
}

.banner__subtitle {
  font-weight: $banner-subtitle_font-weight;
  
  &.banner__subtitle--home {
    @include rem(font-size, $banner-subtitle-home_font-size);
    @include media-breakpoint-up(lg) {
      @include rem(font-size, $banner-subtitle-home_font-size-lg);
    }
  }
}