.section {
  & + & {
    @include rem(margin-top, $section_margin-y);
  }
}

.section__title {
  @include rem(font-size, $section-title_font-size);
  font-weight: $section-title_font-weight;
  @include rem(margin-bottom, 20px);
  text-align: center;
  @include media-breakpoint-up(xl) {
    text-align: left;
  }
}