.spinner {
  @include block-center;
  border: $spinner-rotator_height solid #f3f3f3;
  border-top: $spinner-rotator_height solid $spinner-rotator_bgcolor;
  border-radius: 50%;
  width: $spinner_wh;
  height: $spinner_wh;
  animation: spin 2s linear infinite;
}

.spinner--type-1 {
  border-top-color: $spinner-rotator-type-1_bgcolor;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}