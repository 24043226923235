/** JCAROUSEL v0.3.4 **/

// number of image per row can edit in javascript (jcarousel.responsive.js)

.jcarousel-wrapper {
  position: relative;
}
.jcarousel {
  position: relative;
  overflow: hidden;
  width: 100%;
  ul {
    width: 20000em;
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      width: 200px;
      height: 188px;
      float: left;
      border-right: 1px solid #fff;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      @media (max-width: 646px) {
        max-height: initial;
      }
      a {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          background-color: $color-4;
          opacity: 0;
          transition: 0.5s all;
        }
        &:hover {
          &::before {
            opacity: .8;
          }
        }
      }
    }
  }
  img {
    display: block;
    max-width: 100%;
    height: auto !important;
  }
}

/** Carousel Controls **/
.jcarousel-control-prev,
.jcarousel-control-next {
  position: absolute;
  top: 50%;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  text-align: center;
  background: $color-5;
  color: #fff;
  text-decoration: none;
  text-shadow: 0 0 1px #000;
  font: 24px/27px Arial, sans-serif;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  transition: 0.25s all;
  &:hover, &:focus, &:active {
    text-decoration: none;
    background: darken($color-5, 5);
    color: #fff;
  }
}
.jcarousel-control-prev {
  left: 15px;
}
.jcarousel-control-next {
  right: 15px;
}

/** Carousel Pagination **/
.jcarousel-pagination {
  position: absolute;
  bottom: -40px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  margin: 0;
  a {
    text-decoration: none;
    display: inline-block;
    font-size: 11px;
    height: 10px;
    width: 10px;
    line-height: 10px;
    background: #fff;
    color: #4E443C;
    border-radius: 10px;
    text-indent: -9999px;
    margin-right: 7px;
    -webkit-box-shadow: 0 0 2px #4E443C;
    -moz-box-shadow: 0 0 2px #4E443C;
    box-shadow: 0 0 2px #4E443C;
    &.active {
      background: #4E443C;
      color: #fff;
      opacity: 1;
      -webkit-box-shadow: 0 0 2px #F0EFE7;
      -moz-box-shadow: 0 0 2px #F0EFE7;
      box-shadow: 0 0 2px #F0EFE7;
    }
  }
}