.footer-navbar {
  
}

.footer-navbar--type-1 {
  .footer-navbar__item {
    ::before {
      color: $footer-navbar-list-style-type-1_color;
    }
  }
  @include media-breakpoint-up(sm) {
    column-count: 2;
    column-width: 30px;
  }
}

.footer-navbar--type-2 {
  .footer-navbar__item {
    ::before {
      color: $footer-navbar-list-style-type-2_color;
    }
  }
}

.footer-navbar__nav {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-navbar__item {
  ::before {
    content: "\f105";
    display: inline-block;
    @include rem(font-size, $footer-navbar-list-style_font-size);
    font-family: $font-family-font-awesome;
    @include rem(margin-right, 15px);
    // - only text decoration for text, not for list style item
    text-decoration: none;
  }
}