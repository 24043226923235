

// WE ARE MOBILE FIRST

// WE USE BOOTSTRAP

// WE APPLY BEM


/*************************************************One file to rule them all,********************************************
****************************************************one file to find them,**********************************************
*************************************************one file to bring them all,********************************************
**********************************************and in the sass way merge them.******************************************/


// VENDORS
@import "vendors/rem/rem";
@import "vendors/bootstrap/bootstrap";
//@import "vendors/fontawesome/fontawesome";
@import "vendors/photoswipe/photoswipe";


// ABSTRACTS
@import "abstracts/variables";
@import "abstracts/mixins";


// LIGHTBOX
// - need to load lightbox here because of global variables in abstracts/_variables.scss
//   file
@import "vendors/lightbox/lightbox";


// BASE
@import "base/default";


// COMPONENTS
@import "components/buttons";
@import "components/calendar";
@import "components/jcarousel";
@import "components/modal";
@import "components/pagination";
@import "components/spinner";


// LAYOUT
@import "layout/navbar";
@import "layout/footer";
@import "layout/forms";
@import "layout/header";
@import "layout/topbar";
@import "layout/banner";
@import "layout/pic-links";
@import "layout/footer";
@import "layout/footer-navbar";
@import "layout/bottombar";
@import "layout/section";
@import "layout/main";
@import "layout/article-items";
@import "layout/article";
@import "layout/breadcrumb";
@import "layout/event-detail";
@import "layout/event-items";
@import "layout/page";