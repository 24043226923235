.article-items {
  
}

.article-item {
  background-color: $article-item_bgcolor;
  border-radius: $border-radius-base;
  box-shadow: $box-shadow-base;
  @include block-center;
  max-width: $article-item_max-width;
  overflow: hidden;
  & + & {
    @include rem(margin-top, 30px);
  }
  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.article-item__img {
  background-size: cover;
  height: $article-item-img_height;
  @include media-breakpoint-up(md) {
    width: $article-item-img_width;
  }
}

.article-item__main-block {
  display: flex;
  flex-direction: column;
  @include rem(padding, 15px);
  @include media-breakpoint-up(md) {
    @include rem(padding, 20px 30px);
    width: calc(100% - #{$article-item-img_width});
  }
}

.article-item__title {
  border-bottom: 1px solid $article-item-title_border-bottom-color;
  @include rem(font-size, $article-item-title_font-size);
  font-weight: $article-item-title_font-weight;
  @include rem(margin-bottom, $article-item-main-in_padding-margin-y);
  @include rem(padding-bottom, $article-item-main-in_padding-margin-y);
}

.article-item__intro-text {
  color: $article-item-intro-text_color;
  @include rem(font-size, $article-item-intro-text_font-size);
  line-height: $article-item-intro-text_line-height / $article-item-intro-text_font-size
}

.article-item__footer {
  align-items: flex-end;
  display: flex;
  flex: 1 0 auto;
  flex-wrap: wrap;
  justify-content: space-between;
  @include rem(margin-top, $article-item-main-in_padding-margin-y);
}

.article-item__pubdate-block {
  color: $article-item-pubdate-block_color;
  @include rem(font-size, $article-item-pubdate-block_font-size);
}

.article-item__pubdate {
  display: inline-block;
  // - margin top because when pubdate is under button - mainly on extra small 
  //   devices
  @include rem(margin-top, 10px);
}

.article-item__pubdate-icon {
  @include rem(margin-left, 10px);
}