.btn {
  @include rem(font-size, $btn_font-size);
  font-weight: $btn_font-weight;
  @include rem(padding, 10px 15px);
  transition: $trans-speed-base  background-color;
  @include hover-focus-active {
    box-shadow: none;
    transition: $trans-speed-base  background-color;
  }
}

.btn--arrow-right {
  display: flex;
  justify-content: space-between;
  width: $btn-arrow-right_width;
  text-align: left;
  &::after {
    content: "\f061";
    font-family: $font-family-font-awesome;
    @include rem(margin-left, 15px);
  }
}

.btn--long {
  @include media-breakpoint-up(md) {
    @include rem(padding-left, $btn-long_padding-y);
    @include rem(padding-right, $btn-long_padding-y);
  }
}

.btn--type-1 {
  background-color: $btn-type-1_bgcolor;
  border: 1px solid $btn-type-1_border-color;
  border-radius: $border-radius-base;
  color: $btn-type-1_color;
  @include bg3dbottom-maker($btn-type-1_bgcolor);
  @include hover-focus-active {
    background-color: $btn-type-1_hover-bgcolor;
    color: $btn-type-1_color;
  }
}

.btn--type-2 {
  background-color: $btn-type-2_bgcolor;
  border: 1px solid $btn-type-2_border-color;
  border-radius: $border-radius-base;
  color: $btn-type-2_color;
  @include bg3dbottom-maker($btn-type-2_bgcolor);
  @include hover-focus-active {
    background-color: $btn-type-2_hover-bgcolor;
    color: $btn-type-2_color;
  }
}

.btn--type-3 {
  background-color: $btn-type-3_bgcolor;
  border: 1px solid $btn-type-3_border-color;
  border-radius: $border-radius-base;
  color: $btn-type-3_color;
  @include bg3dbottom-maker($btn-type-3_bgcolor);
  @include hover-focus-active {
    background-color: $btn-type-3_hover-bgcolor;
    color: $btn-type-3_color;
  }
}

// - btn control block after some section
// - example: after article items on homepage - there is btn control block for
//   btn link to another articles
.btn-control-block {
  @include rem(margin-top, $btn-control-block_margin-top);
}