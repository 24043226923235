.modal {
  // - because bootstrap js calculate right padding and apply result in html style
  //   attribute
  padding-right: 0 !important;
}

.modal-dialog {
  @include media-breakpoint-up(md) {
    max-width: 700px;
  }
}
  
.modal-content {
  border: 0;
  @include rem(padding, $modal-content_padding);
  @include media-breakpoint-up(sm) {
    @include rem(padding, $modal-content_padding-lg);
  }
}
  
.modal-header {
  border: 0;
  padding: 0;
  
  .close {
    cursor: pointer;
    @include rem(padding, $modal-close_padding);
  }
}

.modal-title {
  color: $modal-title_color;
  line-height: 1.2;
  @include rem(font-size, $modal-title_font-size);
}

.modal-body {
  padding: 0;
}

.modal-backdrop {
  background-color: $modal-backdrop_bgcolor;
  
  &.show {
    opacity: .8;
  }
}