* {
  outline: none !important;
}

html {
  // - calc root font size for rem units of the whole website
  @include rem-baseline;
}

body {
  background-color: $bgcolor;
  color: $color-4;
  font-family: $font-family-base;
  @include rem(font-size, $font-size-base);
  font-weight: $font-weight-base;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  @include rem(margin-bottom, $h_margin-bottom);
}

a {
  color: $link_color;
  @include hover-focus-active {
    color: $link_color;
  }
}