


/** PATHS **/
$theme_path : "../..";
$media_path : "#{$theme_path}/media";
$image_path : "#{$media_path}/images";
$image_assets_path : "#{$image_path}/assets";
$text-icon_path: "#{$image_path}/text-icon";



/** FONTS **/
$font-family-base: "Roboto", Helvetica, Arial, sans-serif;
$font-family-secondary: "WorkSans", Helvetica, Arial, sans-serif;

$font-family-font-awesome: FontAwesome;

$font-size-base: 15px;
$font-weight-base: 400;



/** MEDIA BREAKPOINTS **/



/** SASS REM node module **/
// rem baseline value for root html element (uses for calculate rem units)
$rem-baseline: $font-size-base;

// fallback unit
$rem-fallback: false;



/** COLORS **/
$color-1: #333;
$color-2: #fff;
$color-3: #00bcd4;
$color-4: #4c5767;
$color-5: #a4c51c;
$color-6: #bbb;
$color-7: #5e995a;
$color-8: #ffb400;
$color-9: #e03a3a;
$color-10: #974344;
$color-11: #e6e6e6;
$color-12: #b97e47;
$color-13: #1c2a3e;

$bgcolor: #f7f7f7;
$link_color: #008fd5;



/** LAYOUT **/
$border-radius-base: 8px;
$box-shadow-base: 0 8px 15px 0 rgba(0, 0, 0, .16);
$trans-speed-base: .5s;

$h_margin-bottom: 15px;



/** BUTTONS **/
// fonts
$btn_font-size: 15px;
$btn_font-weight: 500;

// colors
$btn-type-1_bgcolor: $color-5;
$btn-type-1_hover-bgcolor: darken($btn-type-1_bgcolor, 5);
$btn-type-1_border-color: $color-7;
$btn-type-1_color: #fff;

$btn-type-2_bgcolor: $color-8;
$btn-type-2_hover-bgcolor: darken($btn-type-2_bgcolor, 5);
$btn-type-2_border-color: $color-12;
$btn-type-2_color: #fff;

$btn-type-3_bgcolor: $color-9;
$btn-type-3_hover-bgcolor: darken($btn-type-3_bgcolor, 5);
$btn-type-3_border-color: $color-10;
$btn-type-3_color: #fff;

// layout
$btn-arrow-right_width: 140px;

$btn-long_padding-y: 60px;

$btn-control-block_margin-top: 30px;



/** BREADCRUMB **/
// fonts
$breadcrumb_font-size: 11px;

// colors
$breadcrumb-link_color: #73bc6e;
$breadcrumb-link-active_color: #fff;

$breadcrumb-separator_color: #767878;

// layout
$breacrumb-item_margin-x: 10px;



/** SECTION **/
// fonts
$section-title_font-size: 28px;
$section-title_font-weight: 700;

// colors
$section-title_color: $color-4;

// layout
$section_margin-y: 44px;



/** TOPBAR **/
// fonts
$topbar_font-size: 13px;
$topbar_font-weight: 500;

// colors
$topbar_bgcolor: $bgcolor;
$topbar_bgcolor-start: #ffffff;
$topbar_bgcolor-end: $topbar_bgcolor;

$topbar_color: #B2B2B2;

$topbar-item-icon_color: #008fd5;

// layout
$topbar-item-icon_width-xs: 30px;

$topbar-item_margin-x: 15px;



/** NAVBAR **/
// fonts
$navbar_font-size: 15px;
$navbar_font-weight: 500;
$navbar_line-height: 20px;

// colors
$navbar_bgcolor: #fff;

$navbar-link_color: $color-4;
$navbar-link_hover-color: $color-4;

$navbar-dropdown_bgcolor: #a4d307;
$navbar-dropdown_color: #fff;
$navbar-dropdown_font-weight: 500;

$navbar-form-search_color: $color-6;
$navbar-form-search_border-color: #e1e1e1;

$logo_bgcolor: #fff;

// layout
$navbar_padding-y: 15px;

$navbar-link_padding-lg-x: 15px;
$navbar-link_padding-lg-y: 15px;

$navbar-dropdown-menu_width-lg: 250px;

$logo_wh: 100px;
$logo_padding-lg: 20px;
// - wanna have padding but still the same width and height
// - do not use border-box: content because need this value also for
//   another properties (in more elements) and now I do not have to calc this value
//   on more places
$logo_wh-lg: $logo_wh + ($logo_padding-lg * 2);

$navbar-collapse_margin-left-lg: $logo_wh-lg;

$navbar-btn-search_width: 25px;



/** BANNER **/
// fonts
$banner-title-home_font-size: 40px;
$banner-title-home_font-size-lg: 73px;

$banner-title-page_font-size: 28px;

$banner-title_font-weight: 700;

$banner-subtitle-home_font-size: 26px;
$banner-subtitle-home_font-size-lg: 36px;

$banner-subtitle_font-weight: 500;

$banner_padding-y: 26px;

$banner-home-heading_padding-bottom: 101px;
$banner-home-heading_padding-bottom-lg: 191px;
$banner-home-heading_padding-top: 91px;
$banner-home-heading_padding-top-lg: 161px;

// colors
$banner_bgcolor: #000;
$banner_bgcolor-cover: $color-13;
$banner_color: #fff;

// layout
$banner-title-page_margin-bottom: 2px;

$banner-content-page_margin-left-md: 60px;



/** PIC LINKS **/
// fonts
$pic-link-item-title_font-size: 18px;
$pic-link-item-text_font-size: 14px;

// colors 
$pic-link-item_color: #fff;

$pic-link-item-type-1_border-color: $color-7;
$pic-link-item-type-1_bgcolor: $color-5;
$pic-link-item-type-1_hover-bgcolor: darken($pic-link-item-type-1_bgcolor, 5);

$pic-link-item-type-2_border-color: $color-12;
$pic-link-item-type-2_bgcolor: $color-8;
$pic-link-item-type-2_hover-bgcolor: darken($pic-link-item-type-2_bgcolor, 5);

$pic-link-item-type-3_border-color: $color-10;
$pic-link-item-type-3_bgcolor: $color-9;
$pic-link-item-type-3_hover-bgcolor: darken($pic-link-item-type-3_bgcolor, 5);

// layout
$pic-link-item-icon_max-height: 150px;

$pic-link-item_max-width: 430px;
$pic-link-item_margin: 15px;
$pic-link-item_margin-bottom: 10px;
$pic-link-item_padding: 15px;

$pic-link-item-home_padding-x-lg: 5px;

$pic-link-item-home_width-sm: $pic-link-item_max-width;
$pic-link-item-home_width-lg: 350px;



/** ARTICLE ITEMS **/
// fonts
$article-item-title_font-size: 25px;
$article-item-title_font-weight: 500;

$article-item-intro-text_font-size: 14px;
$article-item-intro-text_line-height: 22px;

$article-item-pubdate-block_font-size: 13px;

// colors
$article-item_bgcolor: #fff;

$article-item-title_border-bottom-color: #d5d5d5;

$article-item-intro-text_color: #95989a;

$article-item-pubdate-block_color: $color-6;

// layout
$article-item_max-width: 780px;

$article-item-main-in_padding-margin-y: 15px;

$article-item-img_width: 270px;
$article-item-img_height: 260px;



/** ARTICLE **/
// fonts
$article-pubdate_font-size: 12px;

// colors

// layout
$article-title_margin-bottom: 5px;



/** FOOTER **/
// fonts
$footer-col-title_font-size: 20px;
$footer-col-title_font-weight: 500;

$footer-main_font-size: 14px;
$footer-main_line-height: 30px;

// colors
$footer-main_color: #9faec7;
$footer-main_bgcolor: #2e3a4d;

$footer-col-title_color: #fff;

$contact-item-label_color: $color-5;

// layout
$footer-col_margin-top: 30px;

$footer-main_padding-y: 50px;

$contact-item-label_width: 130px;



/** FOOTER NAVBAR **/
// fonts
$footer-navbar-list-style_font-size: 11px;

// colors
$footer-navbar-list-style-type-1_color: $color-8;
$footer-navbar-list-style-type-2_color: $color-9;

// layout



/** BOTTOMBAR **/
// fonts
$bottombar_font-size: 13px;

// colors
$bottombar_color: #79879b;
$bottombar_bgcolor: #263140;
$bottombar_border-color: #394456;

// layout
$bottombar_padding-y: 30px;



/** EVENT **/
// fonts
$event-item-date_font-weight: 700;

$event-item-date-day_font-size: 30px;

$event-item-date-month_font-size: 10px;

$event-item-name_font-size: 15px;
$event-item-name_font-weight: 700;

// colors
$event-item_bgcolor: $color-11;

$event-item-date_bgcolor: $color-9;
$event-item-date-hover_bgcolor: darken($event-item-date_bgcolor, 5);
$event-item-date_border-color: $color-10;
$event-item-date_color: #fff;
$event-item-name_color: $color-4;

// layout
$event-item_padding-base: 15px;

$event-item-date_width-sm: 70px;



/** EVENT DETAIL **/
// fonts
$event-detail-time_font-size: 12px;

// layout
$event-detail-text_margin-top: 20px;



/** CALENDAR **/
// fonts
$calendar_font-weight: 700;

$calendar-heading_font-size: 18px;

$calendar-heading-month_line-height: 30px;

$calendar-table_font-size: 14px;

$calendar-event-list-box_line-height: 1.3;

$calendar-event-list-box-day_font-size: 15px;
$calendar-event-list-box-day_font-weight: 700;

$calendar-event-list-item_font-weight: 400;

$calendar-event-list-item-title_font-size: 14px;

$calendar-event-list-item-time_font-size: 12px;

// colors
$calendar_bgcolor: $color-11;
$calendar_color: $color-4;

$calendar-heading-label_bgcolor: $color-8;
$calendar-heading-label_color: #fff;

$calendar-body_bgcolor: #fff;

$calendar-day-active_bgcolor: $bgcolor;

$calendar-day-event-after_bgcolor: $color-8;

$calendar-day-diff-month_color: #d9d9d9;

$calendar-event-list-box_bgcolor: $color-8;
$calendar-event-list-box_border-color: #b97f47;
$calendar-event-list-box_color: #fff;

$calendar-event-list-box-day_color: $color-4;

$calendar-event-list-item-title_color: #fff;
$calendar-event-list-item-time_color: $color-4;

// layout
$calendar_border-radius: 4px;
$calendar_padding: 4px;

$calendar-heading_padding-x: 5px;
$calendar-heading_padding-y: 15px;

$calendar-heading_margin-bottom: 2px;

$calendar-table-label_padding-y: 8px;

$calendar-day_border-width: 1px;
$calendar-day_line-height: 50px;

$calendar-day-event-after_margin: 3px;

$calendar-prev-next-btn_padding-x: 15px;

$calendar-event-list-box_bottom: $calendar-day_line-height - 3px;
$calendar_event-list-box_border-radius: $calendar_border-radius;
$calendar-event-list-box_width: 272px;
$calendar-event-list-box_padding: 20px;

$calendar-event-list-box-arrow_wh: 10px;
$calendar-event-list-box-arrow_border-wh: $calendar-event-list-box-arrow_wh + 2px;

$calendar-event-list-box-content_margin-top: 10px;

$calendar-event-list-item_margin-top: $calendar-event-list-box-content_margin-top;



/** SPINNER **/
// colors
$spinner-rotator_bgcolor: #3498db;

$spinner-rotator-type-1_bgcolor: $color-8;

// layout
$spinner_wh: 60px;

$spinner-rotator_height: 10px;



/** PAGE **/
// colors
$page-content__bgcolor: #fff;

// layout
$page-content__padding: 15px;
$page-content__padding-md: 40px;



/** MODAL **/
// fonts
$modal-title_font-size: 28px;

// colors
$modal-title_color: $color-4;

$modal-backdrop_bgcolor: $color-13;

// layout
$modal-close_padding: 5px;

$modal-content_padding: 15px;
$modal-content_padding-lg: 30px;