.topbar {
  background: $topbar_bgcolor;
  @include gradient-x($topbar_bgcolor-start, $topbar_bgcolor-end);
  color: $topbar_color;
  font-family: $font-family-secondary;
  @include rem(font-size, $topbar_font-size);
  font-weight: $topbar_font-weight;
  overflow: hidden;
  @include rem(padding, 16px 0);
  
  // - because WYSIWYG editor add paragraph around my html content in topbar
  //   for widget
  p {
    margin: 0;
  }
}

.topbar__inner {
  @include media-breakpoint-up(sm) {
    float: right;
    
    // - remove margin left and right because of margin of topbar-item(s)
    // - don't wanna have margin left and right, only between topbar-item(s)
    @include rem(margin-left, -$topbar-item_margin-x);
    @include rem(margin-right, -$topbar-item_margin-x);
  }
}

.topbar-item {
  @include media-breakpoint-down(xs) {
    // - for extra small devices in the whole row
    display: block;
  }
  @include media-breakpoint-up(sm) {
    @include rem(margin-left, $topbar-item_margin-x);
    @include rem(margin-right, $topbar-item_margin-x);
  }
}

.topbar-item__icon {
  color: $topbar-item-icon_color;
  @include rem(margin-right, 15px);
  @include media-breakpoint-down(xs) {
    // - the same align text for all topbar-item(s)
    // - for this effect, we need set width for topbar-item__icon
    // - !important - because we need rewrite default value of more specific
    //   selector of Font Awesome library
    width: $topbar-item-icon_width-xs !important;
  }
} 