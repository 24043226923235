

@mixin font-maker($font-size, $font-weight) {
  font-size: $font-size;
  font-weight: $font-weight;
}


@mixin bg-svg-fallback($path, $name) {
  background-image: url("#{$path}/#{$name}.png");
  background-image: url("#{$path}/#{$name}.svg");
}


@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
}


@mixin arrow-maker($size, $weight, $color, $rotate) {
  display: inline-block;
  width: $size;
  height: $size;
  border-right: $weight solid $color;
  border-top: $weight solid $color;
  transform: rotate($rotate);
  transition: 0.25s all;
}


@mixin icon-maker ($class-name, $icon-name, $width, $height, $path, $hover){


  .#{$class-name} {
    position: relative;
    display: inline-block;
    width: $width;
    height: $height;



    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      transition: 0.25s opacity;
    }



    @if $hover == yes {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        transition: 0.25s opacity;
      }
    }



    &::before {
      @include bg-svg-fallback($icons_path, $icon-name);
      opacity: 1;
    }




    @if $hover == yes {
      &::after {
        @include bg-svg-fallback($icons_path, "#{$icon-name}-hover");
        opacity: 0;
      }
    }



    @if $hover == yes {
      &:hover {
        &::before {
          opacity: 0;
        }
        &::after {
          opacity: 1;
        }
      }
    }



  }

}


@mixin block-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}


// - use for make 3D background - for example use for buttons in this project (zslabska)
@mixin bg3dbottom-maker ($bgcolor, $anotherBoxShadows: '') {
  @if ($anotherBoxShadows == '') {
    box-shadow: inset 0px -4px 1px $bgcolor, inset 0px -4px 1px #fff !important;
  } @else {
    box-shadow: inset 0px -4px 1px $bgcolor, inset 0px -4px 1px #fff, $anotherBoxShadows !important;
  }
}