.breadcrumb {
  background-color: transparent;
  font-size: $breadcrumb_font-size;
  // - set negative value because of breadcrumb__item - has padding on axis x 
  //   and we do not have padding on the left and right side of breadcrumb
  @include rem(margin-left, -$breacrumb-item_margin-x);
  @include rem(margin-right, -$breacrumb-item_margin-x);
  padding: 0;
  margin-bottom: 0;
}

.breadcrumb__item {
  @include rem(margin-left, $breacrumb-item_margin-x);
  &::after {
    color: $breadcrumb-separator_color;
    content: "/";
    display: inline-block;
    @include rem(margin-left, $breacrumb-item_margin-x);
  }
  &:last-child {
    &::after {
      content: "";
    }
    .breadcrumb__link {
      color: $breadcrumb-link-active_color;
    }
  }
}

.breadcrumb__link {
  color: $breadcrumb-link_color;
  @include hover-focus-active {
    color: $breadcrumb-link_color;
  }
}