.event-detail {
  
}

.event-detail__time {
  @include rem(font-size, $event-detail-time_font-size);
}

.event-detail__text {
  @include rem(margin-top, $event-detail-text_margin-top);
}