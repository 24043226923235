.bottombar {
  background: $bottombar_bgcolor;
  border-top: 1px solid $bottombar_border-color;
  color: $bottombar_color;
  @include rem(font-size, $bottombar_font-size);
  @include rem(padding, $bottombar_padding-y 0);
}

.bottombar__inner {
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
  @include media-breakpoint-up(md) {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.copyright {
  display: block;
  @include media-breakpoint-up(md) {
    display: inline-block;
    vertical-align: middle;
  }
}

.created-by {
  display: block;
  @include media-breakpoint-down(sm) {
    @include rem(margin-top, 6px);
  }
  @include media-breakpoint-up(md) {
    display: inline-block;
    vertical-align: middle;
  }
}

.created-by__link {
  opacity: .6;
  transition: $trans-speed-base opacity;
  @include hover-focus-active {
    opacity: 1;
    transition: $trans-speed-base opacity;
  }
}

.created-by__logo {
  @include rem(margin-left, 5px);
  opacity: .6;
  transition: $trans-speed-base opacity;
  @include hover-focus-active {
    opacity: 1;
    transition: $trans-speed-base opacity;
  }
}