.calendar-container {
  align-items: center;
  display: flex;
  min-height: 360px;
}

.calendar {
  background-color: $calendar_bgcolor;
  border-radius: $calendar_border-radius;
  color: $calendar_color;
  font-weight: $calendar_font-weight;
  @include rem(padding, $calendar_padding);
  width: 100%;
}

.calendar-heading {
  align-items: center;
  display: flex;
  @include rem(font-size, $calendar-heading_font-size);
  justify-content: space-between;
  @include rem(padding, $calendar-heading_padding-y $calendar-heading_padding-x);
}

.calendar-heading__btn {
  color: $calendar_color;
  @include rem(padding-left, $calendar-prev-next-btn_padding-x);
  @include rem(padding-right, $calendar-prev-next-btn_padding-x);
  @include hover-focus-active {
    color: $calendar_color;
  }
}

.calendar-table {
  border-collapse: separate;
  border-spacing: 1px;
  @include rem(font-size, $calendar-table_font-size);
  margin-bottom: 0;
  text-align: center;
}

.calendar-table__label {
  background-color: $calendar-heading-label_bgcolor;
  // - important because of more specific bootstrap selector
  border: 0 !important;
  color: $calendar-heading-label_color;
  line-height: 1.2;
  // - important because of more specific bootstrap selector
  @include rem(padding-bottom, $calendar-table-label_padding-y !important);
  @include rem(padding-top, $calendar-table-label_padding-y !important);
  
  &:first-child {
    border-bottom-left-radius: $calendar_border-radius;
    border-top-left-radius: $calendar_border-radius;
  }
  
  &:last-child {
    border-bottom-right-radius: $calendar_border-radius;
    border-top-right-radius: $calendar_border-radius;
  }
}

.calendar-table__tbody {
  border: 0;
  // - space between tbody and thead (more space than between th and td elements)
  &::before {
    color: transparent;
    content: "";
    display: block;
    height: 2px;
  }
}

.calendar-table__content-row {
  &:first-child {
    .calendar-table__day {
      &:first-child {
        border-top-left-radius: $calendar_border-radius;
      }
      
      &:last-child {
        border-top-right-radius: $calendar_border-radius;
      }
    }
  }
  
  &:last-child {
    .calendar-table__day {
      &:first-child {
        border-bottom-left-radius: $calendar_border-radius;
      }
      
      &:last-child {
        border-bottom-right-radius: $calendar_border-radius;
      }
    }
  }
}

.calendar-table__day {
  background-color: $calendar-body_bgcolor;
  // - important because of more specific bootstrap selector
  border: 0 !important;
  line-height: $calendar-day_line-height;
  // - important because of more specific selector in bootstrap
  padding: 0 !important;
  position: relative;
}

.calendar-table__day-event {
  color: inherit;
  display: block;
  position: relative;
  width: 100%;
  &::after {
    background-color: $calendar-day-event-after_bgcolor;
    content: "";
    display: block;
    // - * 2 because of left and right margin
    width: calc(100% - #{$calendar-day-event-after_margin * 2});
    height: 3px;
    @include rem(margin, $calendar-day-event-after_margin);
    position: absolute;
    bottom: 0;
  }
  @include hover-focus-active {
    background-color: $calendar-day-active_bgcolor;
    color: inherit;
    cursor: pointer;
    text-decoration: none;
    
    .calendar-event-list-box {
      display: block;
    }
  }
}

// - day which is not in the specific month of the year
.calendar-table__day--diff-month {
  color: $calendar-day-diff-month_color;
  cursor: not-allowed;
}

.calendar-event-list-box {
  background-color: $calendar-event-list-box_bgcolor;
  border: 1px solid $calendar-event-list-box_border-color;
  border-radius: $calendar_event-list-box_border-radius;
  color: $calendar-event-list-box_color;
  cursor: initial;
  display: none;
  line-height: $calendar-event-list-box_line-height;
  width: $calendar-event-list-box_width;
  position: absolute;
  bottom: $calendar-event-list-box_bottom;
  left: 50%;
  margin-left: -($calendar-event-list-box_width / 2);
  @include rem(padding, $calendar-event-list-box_padding);
  text-align: left;
  z-index: 1040;
  // ============= "arrow" using only css styles =============
  &::before { // - we need to make border around "arrow"
    border-left: $calendar-event-list-box-arrow_border-wh solid transparent;
    border-right: $calendar-event-list-box-arrow_border-wh solid transparent;
    border-top: $calendar-event-list-box-arrow_border-wh solid $calendar-event-list-box_border-color;
    // - negative because we need to show "arrow" and it must be outside of parent
    //   element
    bottom: -$calendar-event-list-box-arrow_border-wh;
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    margin-left: -$calendar-event-list-box-arrow_border-wh;  
  }
  &::after {
    border-left: $calendar-event-list-box-arrow_wh solid transparent;
    border-right: $calendar-event-list-box-arrow_wh solid transparent;
    border-top: $calendar-event-list-box-arrow_wh solid $calendar-event-list-box_bgcolor;
    // - negative because we need to show "arrow" and it must be outside of parent
    //   element
    bottom: -$calendar-event-list-box-arrow_wh;
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    margin-left: -$calendar-event-list-box-arrow_wh;  
  }
  // ============= end "arrow" using only css styles =============
}

.calendar-event-list-box__day {
  color: $calendar-event-list-box-day_color;
  font-weight: $calendar-event-list-box-day_font-weight;
  @include rem(font-size, $calendar-event-list-box-day_font-size);
}

.calendar-event-list-box__content {
  margin-top: $calendar-event-list-box-content_margin-top;
}

.calendar-event-list-item {
  font-weight: $calendar-event-list-item_font-weight;
  margin: 0;
  padding: 0;
  & + & {
    @include rem(margin-top, $calendar-event-list-item_margin-top);
  }
}

.calendar-event-list-item__title {
  color: $calendar-event-list-item-title_color;
  @include rem(font-size, $calendar-event-list-item-title_font-size);
  @include hover-focus-active {
    color: $calendar-event-list-item-title_color;
  }
}

.calendar-event-list-item__time {
  color: $calendar-event-list-item-time_color;
  @include rem(font-size, $calendar-event-list-item-time_font-size);
  display: block;
}