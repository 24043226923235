.footer {
  
}

.footer__main {
  background-color: $footer-main_bgcolor;
  color: $footer-main_color;
  @include rem(font-size, $footer-main_font-size);
  line-height: $footer-main_line-height / $footer-main_font-size;
  @include rem(padding-bottom, $footer-main_padding-y);
  @include rem(padding-top, $footer-main_padding-y);
}

.footer__main a {
  color: $footer-main_color;
}

.footer-row {
  // - negative value because of footer-col margin top
  // - we need footer-col margin top because footer col can be on more rows than
  //   only one (mainly on small devices)
  @include rem(margin-top, -$footer-col_margin-top);
}

.footer-col {
  @include rem(margin-top, $footer-col_margin-top);
}

.footer-col__title {
  color: $footer-col-title_color;
  @include rem(font-size, $footer-col-title_font-size);
  font-weight: $footer-col-title_font-weight;
}

.footer-col__content {
  @include rem(margin-top, 20px);
}

.contact-item__label {
  color: $contact-item-label_color;
  font-weight: 400;
  width: $contact-item-label_width;
}