.pic-links {
  &.pic-links--home {
    .pic-link-items {
      // - wanna have a bit of this element in the element above
      @include rem(margin-top, -30px);
      position: relative;
      z-index: 1000;
      @include media-breakpoint-up(sm) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @include rem(margin-left, -$pic-link-item_margin);
        @include rem(margin-right, -$pic-link-item_margin);
        @include rem(margin-bottom, -$pic-link-item_margin);
      }
    }  
    
    .pic-link-item {
      @include media-breakpoint-up(sm) {
        @include rem(margin-left, $pic-link-item_margin);
        @include rem(margin-right, $pic-link-item_margin);
        padding-left: 0;
        width: $pic-link-item-home_width-sm;
      }
      @include media-breakpoint-up(lg) {
        width: $pic-link-item-home_width-lg;
      }       
    }
    
    .pic-link-item--type-1 {
      // - negative value because we need to have icon a bit above from parent element
      // - this value is different than another icon because of different width and
      //   height of picture icon
      .pic-link-item__icon {
        @include media-breakpoint-up(lg) {
          @include rem(margin-top, -70px);
        }
      }
    } 
    
    .pic-link-item--type-2 {
      // - negative value because we need to have icon a bit above from parent element
      // - this value is different than another icon because of different width and
      //   height of picture icon
      .pic-link-item__icon {
        @include media-breakpoint-up(lg) {
          @include rem(margin-top, -25px);
        }
      }
    } 
    
    .pic-link-item--type-3 {
      // - negative value because we need to have icon a bit above from parent element
      // - this value is different than another icon because of different width and
      //   height of picture icon
      .pic-link-item__icon {
        @include media-breakpoint-up(lg) {
          @include rem(margin-top, -45px);
        }
      }
    }  

    .pic-link-item__info {
      @include media-breakpoint-up(lg) {
        @include rem(padding-left, $pic-link-item-home_padding-x-lg);
      }
    }  
    
    .pic-link-item__icon-block {
      @include media-breakpoint-up(lg) {
        max-width: 150px;
        @include rem(padding-left, $pic-link-item-home_padding-x-lg);
      }
    }
  }
}

.pic-link-item {
  border: 2px solid transparent;
  border-radius: $border-radius-base;
  box-shadow: $box-shadow-base;
  color: $pic-link-item_color;
  display: block;
  max-width: $pic-link-item_max-width;
  @include rem(margin-bottom, $pic-link-item_margin-bottom);
  @include block-center;
  transition: $trans-speed-base background-color;
  @include hover-focus-active {
    // - must rewrite default bootstrap color hover focus active link who has more
    //   specific selector
    color: $pic-link-item_color !important;
    text-decoration: none;
    transition: $trans-speed-base background-color;
  }
  @include media-breakpoint-up(sm) {
    align-items: center;
    display: flex;
  } 
}

.pic-link-item--type-1 {
  background-color: $pic-link-item-type-1_bgcolor;
  border-color: $pic-link-item-type-1_border-color;
  @include hover-focus-active {
    background-color: $pic-link-item-type-1_hover-bgcolor;
  }
}

.pic-link-item--type-2 {
  background-color: $pic-link-item-type-2_bgcolor;
  border-color: $pic-link-item-type-2_border-color;
  @include hover-focus-active {
    background-color: $pic-link-item-type-2_hover-bgcolor;
  }
}

.pic-link-item--type-3 {
  background-color: $pic-link-item-type-3_bgcolor;
  border-color: $pic-link-item-type-3_border-color;
  @include hover-focus-active {
    background-color: $pic-link-item-type-3_hover-bgcolor;
  }
}

.pic-link-item__info {
  @include rem(padding, 5px $pic-link-item_padding $pic-link-item_padding);
  @include media-breakpoint-up(sm) {
    flex: 1;
    @include rem(padding-top, $pic-link-item_padding);
  }
}

.pic-link-item__icon-block {
  max-width: 100px;
  margin: 0 auto;
  @include media-breakpoint-up(sm) {
    flex: 1;
    @include rem(margin-right, 5px);
    @include rem(padding-left, $pic-link-item_padding);
  }
}

.pic-link-item__icon {
  @include block-center;
  max-height: $pic-link-item-icon_max-height;
}

.pic-link-item__title {
  display: block;
  @include rem(font-size, $pic-link-item-title_font-size);
  @include rem(margin-bottom, 3px);
}

.pic-link-item__text {
  @include rem(font-size, $pic-link-item-text_font-size);
  margin: 0;
}